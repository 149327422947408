<h1 mat-dialog-title >Adicionar Ruta del Viaje</h1>
<div mat-dialog-content>
  <form [formGroup]="routeForm">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <fieldset style="border-radius: 0.3em">
          <legend>Datos de la ruta</legend>
          <div class="row">
            <div class="col-md-12">
              <div class="position-relative form-group">
                <mat-label class="label-color" style="cursor: pointer" (click)="restoreOrigin()" matTooltip="Recuperar Origen">
                  {{savedOrigin}}
                </mat-label>
                <mat-form-field class="field-full-width" appearance="outline">
                  <mat-label>Nuevo Origen</mat-label>
                  <input type="text" matInput [formControl]="addressOriginControl" [matAutocomplete]="autoOrigen" [required]="true"
                         (input)="clearCoordenadasOrigen()" (focus)="selectOriginInput()" selectOnFocus>
                  <mat-autocomplete #autoOrigen="matAutocomplete" [displayWith]="displayAddressGeocubaFn"
                                    (optionSelected)="onSelectOriginAddress($event)">
                    <mat-option *ngFor="let option of filteredOriginAddressOptions | async" [value]="option"
                      [matTooltip]="option.dirCompleta">
                      {{option.dirCompleta}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="routeForm.controls.lugarOrigen.hasError('required')">Este campo es
                    requerido.
                  </mat-error>
                  <mat-hint align="end" style="cursor: pointer" matTooltip="Copiar" (click)="copyLocalization('Origin')">
                    <strong>Coord. Origen: </strong>
                    {{routeForm.controls.latitudOrigen.value ? routeForm.controls.latitudOrigen.value : '-'}}
                    {{', '}}
                    {{routeForm.controls.longitudOrigen.value ? routeForm.controls.longitudOrigen.value : '-'}}
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="position-relative form-group">
                <mat-form-field class="field-full-width" appearance="outline">
                  <mat-label>Paradas</mat-label>

                  <mat-chip-list #chipList aria-label="Paradas" class="mat-chip-list-stacked example-chip"
                                 cdkDropList
                                 cdkDropListOrientation="vertical"
                                 (cdkDropListDropped)="drop($event)"
                                 (focus)="selectStopsInput()">

                    <!--                    <mat-chip color="primary" selected style="width: auto" matTooltip="Agregar Parada" (click)="newStop()">-->
                    <!--                      <mat-icon>add_circle_outline</mat-icon>-->
                    <!--                    </mat-chip>-->
                    <!--                              [selected]="stop.geoLatitud && stop.geoLongitud ? true : false"-->
                    <mat-chip #chipElement *ngFor="let stop of listStops" [selectable]="selectable" cdkDrag class="example-box"
                              [removable]="removable" (removed)="removeStop(stop)" style="width: auto; height: auto"
                              (keydown)="onChipKeyDown($event)"
                              (selectionChange)="chipSelectionChange($event)"
                              [matTooltip]="stop.geoLatitud && stop.geoLongitud ? stop.geoLatitud + ',' + stop.geoLongitud : 'N/D'">
                      {{'P' + stop.numOrden + ': '}}{{stop.lugar}}
                      <button matChipRemove *ngIf="removable">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input [formControl]="addressStopControl" [matChipInputFor]="chipList" #chipsInput
                           [matChipInputAddOnBlur]="addOnBlur"
                           [matAutocomplete]="autoParadas"
                           (focus)="selectStopsInput()" id="pac-stops-input">
                    <mat-autocomplete #autoParadas="matAutocomplete" [displayWith]="displayStopAddressGeocubaFn" (optionSelected)="onSelectStopAddress($event)">
                      <mat-option *ngFor="let option of filteredStopAddressOptions | async" [value]="option" [matTooltip]="option.dirCompleta">
                        {{option.dirCompleta}}
                      </mat-option>
                    </mat-autocomplete>
                    <!--                            <span matSuffix  matTooltip="
                      " (click)="newStop()">-->
                    <!--                              <mat-icon class="color-mat-icon-button-c">add_circle_outline</mat-icon>&nbsp;-->
                    <!--                            </span>-->
                  </mat-chip-list>
                </mat-form-field>
              </div>
            </div>
            <!--            <div class="col-sm-2">-->
            <!--              <button matTooltip="Adicionar Parada"-->
            <!--                      (click)="addStop()" mat-icon-button style="margin-top: 12px; margin-bottom: 12px;">-->
            <!--                <mat-icon [class.color-mat-icon-button-c]="true">add_circle_outline</mat-icon>-->
            <!--              </button>-->
            <!--            </div>-->
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="position-relative form-group">
                <mat-label class="label-color" style="cursor: pointer" (click)="restoreDestiny()" matTooltip="Recuperar Destino">
                  {{savedDestiny}}
               </mat-label>

                <mat-form-field class="field-full-width" appearance="outline">
                  <mat-label>Nuevo Destino</mat-label>
                  <input type="text" matInput [formControl]="addressDestinyControl" [matAutocomplete]="autoDestino" [required]="true" id="pac-destiny-input"
                         (input)="clearCoordenadasDestino()" (focus)="selectDestinyInput()" selectOnFocus>
                  <mat-autocomplete #autoDestino="matAutocomplete" [displayWith]="displayDestinyAddressGeocubaFn"
                                    (optionSelected)="onSelectDestinyAddress($event)">
                    <mat-option *ngFor="let option of filteredDestinyAddressOptions | async" [value]="option" [matTooltip]="option.dirCompleta">
                      {{option.dirCompleta}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="routeForm.controls.lugarDestino.hasError('required')">Este campo es
                    requerido.
                  </mat-error>
                  <mat-hint align="end" style="cursor: pointer" matTooltip="Copiar" (click)="copyLocalization('Destiny')">
                    <strong>Coord. destino: </strong>
                    {{routeForm.controls.latitudDestino.value ? routeForm.controls.latitudDestino.value : '-'}}
                    {{', '}}
                    {{routeForm.controls.longitudDestino.value ? routeForm.controls.longitudDestino.value : '-'}}
                  </mat-hint>
                </mat-form-field>

              </div>
            </div>
          </div>
          <div class="row" style="padding: 10px 10px;">
            <div class="action-button-container">
              <button mat-raised-button color="primary" (click)="traceRoute()" class="action-button"
                      [disabled]="(routeForm.invalid)" matTooltip="Mostrar la ruta" id="trace-route" type="button"
                      [style]="routeForm.invalid
                        ? 'cursor: not-allowed' : 'cursor: pointer'">
                <mat-icon>signpost</mat-icon>
              </button>
              <button mat-raised-button color="primary" matTooltip="Intercambiar Origen y Destino" type="button"
                      [disabled]="this.routeForm.controls.lugarOrigen.invalid || this.routeForm.controls.lugarDestino.invalid"
                      (click)="changeOriginDestiny()" class="action-button"
                      [style]="this.routeForm.controls.lugarOrigen.invalid || this.routeForm.controls.lugarDestino.invalid
                        ? 'cursor: not-allowed' : 'cursor: pointer'">
                <mat-icon>swap_vert</mat-icon>
              </button>
              <button mat-raised-button type="button" [disabled]="routeForm.controls.lugarOrigen.invalid"
                      class="action-button" color="primary" [style]="this.routeForm.controls.lugarOrigen.invalid
                        ? 'cursor: not-allowed' : 'cursor: pointer'"
                      (click)="createRoute()">
                Aceptar
              </button>
              <button mat-raised-button type="button" color="warn" class="action-button"
                      (click)="onNoClick()">
                Cancelar
              </button>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <fieldset style="border-radius: 0.3em">
          <legend>Mapa</legend>
          <div class="row" style="height: 400px">
            <div class="col-md-12">
              <div id="map" class="leaflet-container"></div>
<!--              <div style="height: 400px;"-->
<!--                   leaflet-->
<!--                   [leafletOptions]="options"-->
<!--                   [leafletLayersControl]="layersControl"-->
<!--                   [leafletLayers]="layers">-->
<!--              </div>-->
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end" *ngIf="false">
  <button mat-raised-button [disabled]="false" color="primary" (click)="createRoute()" >Aceptar</button>
  <button mat-raised-button color="warn" (click)="onNoClick()">Cancelar</button>
</div>
