import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { ApiCodeMessage } from 'src/app/shared/consts/api-code-message.constant';
import { catchError, map } from 'rxjs/operators';
import {
  ActionRolEntity,
  ConfigAntiguedadEntity,
  ConfigCadecaEntity,
  ConfigCategoriaEntity,
  ConfigDiaFestivoPost,
  ConfigDocChoferPost,
  ConfigFactorViajeEntity,
  ConfigHorCalcPost,
  ConfigPrecioMinimoFactorEntity, ConfigSalarioModel, ConfigSalarioRequest,
  ConfigTurnEntity,
  ConfigVariableEntity, Documento
} from 'src/app/shared/models/admin-model';
import {NomenclatorModel, NomenclatorRequest, NomTipoIncClasifRequest} from "../../../shared/models/nomenclator-model";
import {DocumentosDriver} from "../../../shared/models/person-model";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(private _http: HttpClient) { }

   /* Columnas de la tabla Configuración del Sistema */
  getDataColumnsTableSysConfig() {
    return ['nombre','valor','editable','borrar'];
  }
  /* Columnas de la tabla Configuración del Turno */
  getDataColumnsTableTurnoConfig() {
    return ['categoria','cantAdmisible','cantReal','fechaCreacion',];
  }
  /* Columnas de la tabla Configuración del Horario Calculadora */
  getDataColumnsTableHorCalcConfig() {
    return ['nombre','diaSemana','horaInicio','horaFin','porciento','descripcion','activo'];
  }
  /* Columnas de la tabla Configuración precios minimos por factores */
  getDataColumnsTablePreciosMinimosFactorConfig() {
    return ['nombreFactor','tipoViaje','cantPersMin','cantPersMax','precioMinCup','precioXMinuto'];
  }
  /* Columnas de la tabla Configuración del Doc Chofer */
  getDataColumnsTableDocChoferConfig() {
    return ['estado','nombre','tipoDuenoDoc','tipoFichero','prioridad', 'mostrarChofer'];
  }
  /* Columnas de la tabla Configuración Dia Festivo */
  getDataColumnsTableDiaFestivoConfig() {
    return ['fechaDesde','fechaHasta','descripcion',];
  }
   /* Columnas de la tabla Configuración del Factor Viaje */
   getDataColumnsTableFactorViajeConfig() {
    return ['nombre','mostrarCliente','mostrarDespachador','esCaractAuto', 'esCaractMoto', 'esFactorMoto', 'esHorario','factorPorciento', 'activo'];
  }
   /* Columnas de la tabla Configuración de CADECA */
   getDataColumnsTableCadecaConfig() {
    return ['fechaModif','tipoMoneda','compra','venta'];
  }
  /* Columnas de la tabla Configuración de categorias */
  getDataColumnsTableCategoriasConfig() {
    return ['nombre','descCorta','orden','rol','activo','creaTurno','porctoVjePago',];
  }
  /* Columnas de la tabla Nomenclador Tipo de Gasto */
  getDataColumnsTableNomTipoGasto() {
    return ['nombre','descCorta','mostrar','activo'];
  }

  /* Columnas la tabla Configuración de antiguedad */
  getDataColumnsTableConfigAntiguedad() {
    return ['annoDesde', 'annoHasta', 'valor', 'tipoPerson'];
  }

  /* Columnas de la tabla Configuración del Salario */
  getDataColumnsTableSalaryConfig() {
    return ['nombreCategoria','salarioBase','extraCargo','porcProduc'];
  }

  /* Manejo de mensaje de error */
  handleServiceError(error: HttpErrorResponse) {
    let mensajeError = undefined;
    if (error.error instanceof ErrorEvent) {
      console.error('Error:', error.error.message);
      mensajeError = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 400:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error.error;
          break;
        case 500:
          console.error(`Error del backend, código: ${error.status} `);
          break;
        case 503:
          console.error(`Error del backend, código: ${error.status} `);
          mensajeError = ApiCodeMessage.MSG_CODE_503;
          break;
        default:
          console.error(`Error: ${error} `);
          mensajeError = error;
          break;
      }
    }
    return throwError(mensajeError);
  }

  /** Obtener datos Configuración Variable*/
  getConfigVariable(searchParam?: string, esPrecio?:boolean): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let queryParams = new HttpParams();
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    esPrecio
      ? (queryParams = queryParams.append('esPrecio', esPrecio))
      : (queryParams = queryParams.append('esPrecio', false));

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceConfigVariable, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Variable por nombre*/
  getConfigVariableByNombre(nombre: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    nombre
      ? (queryParams = queryParams.append('nombre', nombre))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceConfigVariable, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Variable por ID*/
  getConfigVariableByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigVariable + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Variable */
  editSystemConfig(configVariableEntity: ConfigVariableEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigVariable + `/${id}`, configVariableEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar configuracion */
  addSystemConfig(configVariableEntity: ConfigVariableEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigVariable, configVariableEntity, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }
/** Borrar configuracion */
  deleteSystemConfig(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigVariable + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Turno*/
  getConfigTurno(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigTurno, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Turno por ID*/
  getConfigTurnoByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigTurno + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Turno */
  editConfigTurno(configTurnEntity: ConfigTurnEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigTurno + `/${id}`, configTurnEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuracion Turno*/
  addConfigTurno(configTurnEntity: ConfigTurnEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigTurno, configTurnEntity, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }
  /**Borrar Configuración Turno */
  deleteConfigTurno(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigTurno + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener Configuración de precios minimos*/
  getConfigPreciosMinimosFactores(factorId?: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };

    let endpoint = environment.servicePrecioMinFactores;

    if(factorId)
      endpoint += `?factorId=${factorId}`;

    return this._http.get<any>(endpoint, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración precio minimo factor por id*/
  getConfigPrecioMinimoFactorById(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.servicePrecioMinFactores + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Precio Minimo Factor */
  editConfigPrecioMinimo(configPrecioMinimo: ConfigPrecioMinimoFactorEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.servicePrecioMinFactores + `/${id}`, configPrecioMinimo, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración Precio Minimo Factor */
  addConfigPrecioMinimo(configPrecioMinimo: ConfigPrecioMinimoFactorEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.servicePrecioMinFactores, configPrecioMinimo, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Precio Minimo Factor */
  deleteConfigPrecioMinimo(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.servicePrecioMinFactores + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Horario Calculadora*/
  getConfigHorCalc(factorId?: number, tipo?: string, diaSemana?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let endpoint = environment.serviceConfigHorCalc;

    /*if(factorId)
      endpoint += `?factorId=${factorId}`;*/

    let queryParams = new HttpParams();

    factorId
      ? (queryParams = queryParams.append('factorId', factorId))
      : null;
    tipo
      ? (queryParams = queryParams.append('tipo', tipo))
      : null;
    diaSemana
      ? (queryParams = queryParams.append('diaSemana', diaSemana))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(endpoint, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Horario Calculadora por ID*/
  getConfigHorCalcByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigHorCalc + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Horario Calculadora */
  editConfigHorCalc(configHorCalcPost: ConfigHorCalcPost, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigHorCalc + `/${id}`, configHorCalcPost, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración Horario Calculadora */
  addConfigHorCalc(configHorCalcPost: ConfigHorCalcPost): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigHorCalc, configHorCalcPost, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Horario Calculadora */
  deleteConfigHorCalc(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigHorCalc + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Dia Festivo*/
  getConfigDiaFestivo(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigDiaFestivo, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Dia Festivo ID*/
  getConfigDiaFestivoByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigDiaFestivo + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración  Dia Festivo */
  editConfigDiaFestivo(configDiaFestivoPost: ConfigDiaFestivoPost, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigDiaFestivo + `/${id}`, configDiaFestivoPost, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración  Dia Festivo */
  addConfigDiaFestivo(configDiaFestivoPost: ConfigDiaFestivoPost): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http
      .post<any>(environment.serviceConfigDiaFestivo, configDiaFestivoPost, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Dia Festivo*/
  deleteConfigDiaFestivo(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http
      .delete<any>(environment.serviceConfigDiaFestivo + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Factor Viaje*/
  getConfigFactorViaje(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigFactorViaje, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Factor Viaje*/
  getConfigFactorViajeByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigFactorViaje + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Factor Viaje */
  editConfigFactorViaje(configFactorViajeEntity: ConfigFactorViajeEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigFactorViaje + `/${id}`, configFactorViajeEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración Factor Viaje */
  addConfigFactorViaje(configFactorViajeEntity: ConfigFactorViajeEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigFactorViaje, configFactorViajeEntity, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Factor Viaje */
  deleteConfigFactorViaje(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigFactorViaje + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Doc Chofer*/
  getConfigDocChofer(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigDocChofer, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Doc Chofer ID*/
  getConfigDocChoferByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigDocChofer + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Doc Chofer */
  editConfigDocChofer(configDocChoferEntity: ConfigDocChoferPost, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigDocChofer + `/${id}`, configDocChoferEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración Doc Chofer */
  addConfigDocChofer(configDocChoferEntity: ConfigDocChoferPost): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigDocChofer, configDocChoferEntity, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Doc Chofer */
  deleteConfigDocChofer(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigDocChofer + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Cadeca*/
  getConfigCadeca(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigCadeca, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Doc Chofer ID*/
  getConfigCadecaByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigCadeca + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Cadeca */
  editConfigCadeca(configCadecaEntity: ConfigCadecaEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigCadeca + `/${id}`, configCadecaEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración Cadeca */
  addConfigCadeca(configCadecaEntity: ConfigCadecaEntity,): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigCadeca, configCadecaEntity, options).pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Borrar Configuración Cadeca */
  deleteConfigCadeca(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigCadeca + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos Configuración Categoria*/
  getConfigCategoria(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceCategories, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración Categoria */
  editConfigCategoria(configCategoriaEntity: ConfigCategoriaEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceCategories + `/${id}`, configCategoriaEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos de la categoria por id*/
  getConfigCategoryById(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceCategories + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener todos los roles */
  getRoles(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    const options = {
      headers: headers,
    };

    return this._http.get<any>(environment.serviceRoles, options).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

 applyPorcentagesPreciosMinFactores(minimumPricePorcent?: number, priceXMinPorcent?: number): Observable<any> {

    const headers = new HttpHeaders({
      accept: '*/*',
    });

   let endpoint = environment.servicePrecioMinFactores+ '/';

   if(minimumPricePorcent)
       endpoint += `?porcientoPrecioMin=${minimumPricePorcent}`

   if(priceXMinPorcent) {
     if (!minimumPricePorcent)
       endpoint += `?porcientoPrecioXMinuto=${priceXMinPorcent}`;
     else
       endpoint += `&porcientoPrecioXMinuto=${priceXMinPorcent}`;
   }
   const options = {
     headers: headers
   };

    return this._http.patch<any>(endpoint, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración de antigüedad*/
  getConfigAntiguedad(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigAntiguedad, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración de antigüedad por ID*/
  getConfigAntiguedadByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigAntiguedad + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración de antigüedad*/
  addConfigAntiguedad(configAntiguedadEntity: ConfigAntiguedadEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigAntiguedad, configAntiguedadEntity, options).pipe(
      map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración de antigüedad */
  editConfigAntiguedad(configAntiguedadEntity: ConfigAntiguedadEntity, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigAntiguedad + `/${id}`, configAntiguedadEntity, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /**Borrar Configuración de antigüedad */
  deleteConfigAntiguedad(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigAntiguedad + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /**
   *  Se obtiene el menu dado el id de la categoria
   *
  **/
  getMenuByCategory(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceCategories + `/${id}` + '/menu', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener tipos de documentos por módulo*/
  getConfigDocuments(modulo: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http
      .get<any>(environment.serviceConfDoc + '?activa=true' + '&tipo=' + `${modulo}`, {headers})
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  getDocuments(modulo: string, elementoId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let queryParams = new HttpParams();

    modulo !== undefined
      ? (queryParams = queryParams.append('modulo', modulo))
      : null;
    elementoId !== undefined
      ? (queryParams = queryParams.append('elementoId', elementoId))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceDocuments, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar documento */
  addDocument(reqDocumento: Documento): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceDocuments, reqDocumento, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Editar documento */
  editDocument(documentId: number,reqDocumento: Documento): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceDocuments + `/${documentId}`, reqDocumento, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Eliminar documento*/
  deleteDocument(documentId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    return this._http
      .delete<any>(environment.serviceDocuments + `/${documentId}/`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener todos los módulos con sus acciones asociadas dado un id rol.*/
  getModuleActionsByIdRole(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceNomModulo + '/acciones_asociadas_rol' + '?rolId=' + `${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Actualizar la relación acción-rol*/
  editAccionRolRelation(actionRol: ActionRolEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceAccionRol, actionRol, {
      headers,}).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos Configuración Salario*/
  getSalaryConfig(configSalarioRequest: ConfigSalarioRequest, pageNumber: number, pageSize: number, sort: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let queryParams = new HttpParams();
    configSalarioRequest.categoriaId
      ? (queryParams = queryParams.append('categoriaId', configSalarioRequest.categoriaId))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceConfigSalario, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos de Configuración salario por ID*/
  getSalaryConfigByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceConfigSalario + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Configuración salario*/
  addSalaryConfig(configSalarioModel: ConfigSalarioModel): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceConfigSalario, configSalarioModel, options).pipe(
      map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Configuración salario */
  editSalaryConfig(configSalarioModel: ConfigSalarioModel, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceConfigSalario + `/${id}`, configSalarioModel, {
      headers,
    }).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Eliminar Configuración de salario*/
  deleteSalaryConfig(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceConfigSalario + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }
}
